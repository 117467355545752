<template>
  <div class="card">
    <div class="card-title">
      <span class="card-tab">总</span>
      <span>{{ title1 }}</span>
    </div>
    <div class="mainDiv">
      <div>
        <img :src="imgSrc" width="40" alt="" />
        <span class="money">{{symbol}} {{ value1 || 0 }}</span>
      </div>
      <div>
        <span>{{ title2 }}</span>
        <span>{{ value2 }}</span>
      </div>
      <div>
        <span>{{ title3 }}</span>
        <span>{{ value3 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title1", "value1", "title2", "value2", "title3", "value3", "imgSrc", 'symbol'],
};
</script>

<style lang="less" scoped>
.card {
  display: block;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
  font-size: 14.2px;
  .card-title {
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;
    .card-tab {
      color: #389e0d;
      background: #f6ffed;
      border: 1px solid #b7eb8f;
      display: block;
      width: 25px;
      height: 25px;
      text-align: center;
      line-height: 20px;
    }
  }
  .mainDiv {
    padding: 20px 35px 5px 35px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .money {
        font-size: 25px;
        color: #444;
      }
    }
    > div:first-child {
      padding-bottom: 15px;
    }
  }
}
</style>
